export default {
  state: {
    snippets: [],
    snippet_categories: [],
  },
  mutations: {
    setSnippets(state, payload) {
      state.snippets = payload
    },
  },
  actions: {
    querySnippets({dispatch, commit}) {
      dispatch('setPreloader', true)
      axios.get(process.env.VUE_APP_API_ENDPOINT + 'snippets')
        .then(res => {
          dispatch('setPreloader', false)
          commit('setSnippets', res.data.snippets)
        })
        .catch((error) => {
          dispatch('setToastHttpError', error.response)
        })
    },
    querySnippetCreate({dispatch, commit}, payload) {
      dispatch('setPreloader', true)
      axios.post(process.env.VUE_APP_API_ENDPOINT + 'snippets', payload)
        .then(res => {
          dispatch('setPreloader', false)
          dispatch('setToast', [res.data.message, 'success'])
          commit('setSnippets', res.data.snippets)
        })
        .catch((error) => {
          dispatch('setToastHttpError', error.response)
        })
    },
    querySnippetUpdate({dispatch, commit}, payload) {
      dispatch('setPreloader', true)
      axios.patch(process.env.VUE_APP_API_ENDPOINT + 'snippets/' + payload[0], payload[1])
        .then(res => {
          dispatch('setPreloader', false)
          if (res.data.message)
            dispatch('setToast', [res.data.message, 'success'])
          commit('setSnippets', res.data.snippets)
        })
        .catch((error) => {
          dispatch('setToastHttpError', error.response)
        })
    },
    querySnippetDelete({dispatch, commit}, payload) {
      dispatch('setPreloader', true)
      axios.delete(process.env.VUE_APP_API_ENDPOINT + 'snippets/' + payload)
        .then(res => {
          dispatch('setPreloader', false)
          dispatch('setToast', [res.data.message, 'success'])
          commit('setSnippets', res.data.snippets)
        })
        .catch((error) => {
          dispatch('setToastHttpError', error.response)
        })
    },
    querySnippetCategoryCreate({dispatch, commit}, payload) {
      dispatch('setPreloader', true)
      axios.post(process.env.VUE_APP_API_ENDPOINT + 'snippets/categories', payload)
        .then(res => {
          dispatch('setPreloader', false)
          commit('setSnippets', res.data.snippets)
          dispatch('setToast', [res.data.message, 'success'])
        })
        .catch((error) => {
          dispatch('setToastHttpError', error.response)
        })
    },
    querySnippetCategoryUpdate({dispatch, commit}, payload) {
      dispatch('setPreloader', true)
      axios.patch(process.env.VUE_APP_API_ENDPOINT + 'snippets/categories/' + payload[0], payload[1])
        .then(res => {
          dispatch('setPreloader', false)
          if (res.data.message)
            dispatch('setToast', [res.data.message, 'success'])
          commit('setSnippets', res.data.snippets)
        })
        .catch((error) => {
          dispatch('setToastHttpError', error.response)
        })
    },
    querySnippetCategorySort({dispatch, commit}, payload) {
      dispatch('setPreloader', true)
      axios.patch(process.env.VUE_APP_API_ENDPOINT + 'snippets/categories/sort', {id: payload})
        .then(res => {
          dispatch('setPreloader', false)
          if (res.data.message)
            dispatch('setToast', [res.data.message, 'success'])
          commit('setSnippets', res.data.snippets)
        })
        .catch((error) => {
          dispatch('setToastHttpError', error.response)
        })
    },
  },
  getters: {
    getSnippets: state => {
      return state.snippets
    },
  }
}