<template>
  <v-toolbar height="40px" class="px-0">
    <v-toolbar-title>IT Helper</v-toolbar-title>

    <v-spacer></v-spacer>

    <v-toolbar-items>
      <template v-if="!getAuthStatus">
        <v-btn to="login" text>
          <span class="mx-2">Login</span>
          <v-icon>mdi-login-variant</v-icon>
        </v-btn>
      </template>

      <template v-if="getAuthStatus">
        <v-btn to="dashboard" text>
          <v-icon>mdi-desktop-mac-dashboard</v-icon>
        </v-btn>

        <v-divider vertical/>

        <v-btn to="settings" text>
          <v-icon>mdi-account-cog-outline</v-icon>
        </v-btn>

        <v-divider vertical/>

        <v-btn @click="logout" text>
          <v-icon>mdi-logout-variant</v-icon>
        </v-btn>
      </template>
    </v-toolbar-items>
  </v-toolbar>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex'

  export default {
    name: 'Toolbar',
    computed: {
      ...mapGetters([
        'getAuthStatus',
      ]),
    },
    methods: {
      ...mapActions([
        'logout',
      ]),
    },
  }
</script>

<style>

</style>