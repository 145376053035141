import Vue from 'vue'
import VueRouter from 'vue-router'
import Hello from '../components/HelloWorld.vue'
import Login from '../components/Login'
import Dashboard from '../components/Dashboard'

import Notes from '../components/Helpers/Notes'
import Snippets from '../components/Helpers/Snippets'
import Todo from '../components/Helpers/Todo'

import Json from '../components/Helpers/Json'
import Texts from '../components/Helpers/Texts'

Vue.use(VueRouter)

const title = 'IT Helper'

const routes = [
  {
    path: '/',
    name: 'Hello',
    component: Hello,
    meta: {
      title: title,
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title: 'Login - ' + title,
    },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      title: 'Dashboard - ' + title,
    },
  },
  {
    path: '/notes',
    name: 'Notes',
    component: Notes,
    meta: {
      title: 'Notes - ' + title,
    },
  },
  {
    path: '/snippets',
    name: 'Snippets',
    component: Snippets,
    meta: {
      title: 'Snippets - ' + title,
    },
  },
  {
    path: '/todo',
    name: 'Todo',
    component: Todo,
    meta: {
      title: 'Todo - ' + title,
    },
  },

  {
    path: '/json',
    name: 'Json',
    component: Json,
    meta: {
      title: 'Json - ' + title,
    },
  },
  {
    path: '/texts',
    name: 'Texts',
    component: Texts,
    meta: {
      title: 'Texts - ' + title,
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  next()
})