import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';

import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'
Vue.use(VueToast, {position: 'bottom', duration: 70000})

import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
Vue.use(PerfectScrollbar)

import Clipboard from 'v-clipboard'
Vue.use(Clipboard)

window.axios = require('axios')
// add oauth2 token in headers
const auth = JSON.parse(localStorage.getItem('vuex'))
if (auth && auth.auth) {
  axios.defaults.headers.common = {
    'Accept': 'application/json',
    'Authorization': 'Bearer ' + auth.auth.token
  }
}

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: function (h) { return h(App) }
}).$mount('#app')
