import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

import auth from './auth'
import preloader from './preloader'
import toast from './toast'

import notes from './notes'
import snippets from './snippets'
import todo from './todo'

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    preloader,
    toast,

    notes,
    snippets,
    todo,
  },
  plugins: [
    createPersistedState({
      paths: ['auth']
    })
  ],
})
