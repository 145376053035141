<template>
  <v-app>
    <v-overlay :value.sync="preloaderStatus">
      <v-progress-circular indeterminate size="64"/>
    </v-overlay>

    <v-app-bar id="top" app clipped-left dark elevation="2" height="40px">
      <toolbar/>
    </v-app-bar>

    <v-main>
      <transition>
        <router-view/>
      </transition>
    </v-main>

    <sidebar v-if="getAuthStatus && $route.name !== 'Hello' && $route.name !== 'Login'"/>
  </v-app>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex'
  import Toolbar from "./components/Layouts/Toolbar";
  import Sidebar from "./components/Layouts/Sidebar";

  export default {
    name: 'App',

    components: {
      Sidebar,
      Toolbar
    },

    data: () => ({
      //
    }),

    computed: {
      ...mapGetters([
        'getAuthStatus',
        'preloaderStatus',
      ]),
    },
    methods: {
      ...mapActions([]),
    },
  };
</script>

<style lang="scss">
  html {
    min-width: 800px;
    overflow: auto;
  }

  .transparent-bg {
    background: transparent !important;
  }

  .pre-formatted {
    white-space: pre;
  }

  .word-break {
    word-break: break-all;
  }

  .v-toast__item {
    min-height: inherit !important;
    .v-toast__text {
      padding: 1em 1em !important;
    }
  }

  .v-application--wrap {
    background: #121212;
  }

  #top > .v-toolbar__content {
    padding: 0 !important;
  }
</style>
