<template>
  <div id="texts" class="pa-5">
    section in development
  </div>
</template>

<script>
  export default {
    name: 'Texts'
  }
</script>

<style lang="scss">

</style>