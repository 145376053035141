<template>
  <div id="login">
    <v-container>
      <v-row>
        <v-col cols="12" md="8" lg="6" offset-md="2" offset-lg="3">
          <v-card outlined elevation="2">
            <v-card-title>
              <v-tabs v-model="tab" centered>
                <v-tab>Login</v-tab>
                <v-tab>Registration</v-tab>
                <v-tab>Restore password</v-tab>
              </v-tabs>
            </v-card-title>
            <v-card-text>
              <v-tabs-items v-model="tab">
                <v-tab-item>
                  <v-form @submit.prevent="onLogin()">
                    <v-text-field
                        v-model="form_login.email"
                        label="E-mail"
                        required
                    />
                    <v-text-field
                        v-model="form_login.password"
                        label="Password"
                        :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show_password ? 'text' : 'password'"
                        counter
                        @click:append="show_password = !show_password"
                        required
                    />
                    <v-btn :disabled="$v.form_login.$invalid" color="success" class="mr-4" type="submit">Send</v-btn>
                  </v-form>
                </v-tab-item>
                <v-tab-item>
                  <v-form  ref="form_reg" @submit.prevent="onReg()">
                    <v-text-field v-model="form_reg.name" label="Name" required/>
                    <v-text-field v-model="form_reg.email" label="E-mail" required/>
                    <v-btn :disabled="$v.form_reg.$invalid" color="success" class="mr-4" type="submit">Send</v-btn>
                  </v-form>
                </v-tab-item>
                <v-tab-item>
                  <v-form ref="form_restore">
                    <v-text-field v-model="form_restore.email" :disabled="restore_confirm" label="E-mail"/>
                    <v-btn :disabled="$v.form_restore.email.$invalid" v-if="!restore_confirm" color="success" class="mr-4" @click="onRestore()">Send</v-btn>
                    <v-text-field v-model="form_restore.code" v-if="restore_confirm" label="Code"/>
                    <v-btn :disabled="$v.form_restore.$invalid" v-if="restore_confirm" color="success" class="mr-4" @click="onRestoreConfirm()">Send</v-btn>
                  </v-form>
                </v-tab-item>
              </v-tabs-items>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import {required, minLength, email, sameAs} from 'vuelidate/lib/validators'
  import {validationMixin} from 'vuelidate'
  import {mapActions, mapMutations, mapGetters} from 'vuex'

  export default {
    name: 'Login',
    mixins: [validationMixin],
    data() {
      return {
        tab: 0,
        show_password: false,
        restore_confirm: false,
        form_login: {
          email: '',
          password: '',
        },
        form_reg: {
          name: '',
          email: '',
        },
        form_restore: {
          email: '',
          code: '',
        },
      }
    },
    validations: {
      form_login: {
        email: {required, email},
        password: {required, minLength: minLength(4)},
      },
      form_reg: {
        name: {required},
        email: {required, email},
      },
      form_restore: {
        email: {required, email},
        code: {required},
      },
    },
    mounted() {
      let self = this
      axios.get(process.env.VUE_APP_API_ENDPOINT + 'account')
        .then(res => {
          this.setUser(res.data.user)
          setTimeout(function () {
            self.$router.push('/dashboard')
          }, 200)
        })
        .catch((e) => {
          this.logout()
        })
    },
    computed: {
      ...mapGetters([
        'getAuthUser',
        'getAuthStatus',
      ])
    },
    methods: {
      ...mapMutations([
        'setUser',
        'logout',
      ]),
      ...mapActions([
        'login',
        'registration',
        'restorePassword',
        'restoreConfirm',
      ]),
      onLogin() {
        this.$v.$touch()
        if (!this.$v.form_login.$invalid) {
          this.login(this.form_login)
        }
      },
      onReg() {
        this.$v.$touch()
        if (!this.$v.form_reg.$invalid) {
          this.registration(this.form_reg).then(res => {
            this.$refs.form_reg.reset()
            this.tab = 0
          })
        }
      },
      onRestore() {
        this.$v.$touch()
        if (!this.$v.form_restore.email.$invalid) {
          this.restorePassword(this.form_restore)
          this.restore_confirm = true
        }
      },
      onRestoreConfirm() {
        this.$v.$touch()
        if (!this.$v.form_restore.$invalid) {
          this.restoreConfirm(this.form_restore)
          this.$refs.form_restore.reset()
          this.tab = 0
        }
      },
    }
  }
</script>

<style>

</style>