<template>
  <v-navigation-drawer app permanent clipped width="180" class="mt-100">
    <v-list>
      <v-list-item link>
        <v-list-item-content>
          <v-list-item-title class="title">
            {{ getAuthUser.name }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ getAuthUser.email }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider></v-divider>

    <v-list nav dense>
      <v-list-item link>
        <v-list-item-icon>
          <v-icon>mdi-star</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Favorites</v-list-item-title>
      </v-list-item>
      <v-list-item link to="/notes">
        <v-list-item-icon>
          <v-icon>mdi-note</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Notes</v-list-item-title>
      </v-list-item>
      <v-list-item link to="/todo">
        <v-list-item-icon>
          <v-icon>mdi-format-list-checks</v-icon>
        </v-list-item-icon>
        <v-list-item-title>TODO</v-list-item-title>
      </v-list-item>
      <v-list-item link to="/snippets">
        <v-list-item-icon>
          <v-icon>mdi-file-code-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Snippets</v-list-item-title>
      </v-list-item>
      <v-list-item link>
        <v-list-item-icon>
          <v-icon>mdi-monitor-screenshot</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Screenshots</v-list-item-title>
      </v-list-item>
      <v-list-item link>
        <v-list-item-icon>
          <v-icon>mdi-folder</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Files</v-list-item-title>
      </v-list-item>
      <v-list-item link>
        <v-list-item-icon>
          <v-icon>mdi-api</v-icon>
        </v-list-item-icon>
        <v-list-item-title>REST API</v-list-item-title>
      </v-list-item>
      <v-list-item link>
        <v-list-item-icon>
          <v-icon>mdi-web</v-icon>
        </v-list-item-icon>
        <v-list-item-title>WebSockets</v-list-item-title>
      </v-list-item>
      <v-list-item link>
        <v-list-item-icon>
          <v-icon>$mqtt</v-icon>
        </v-list-item-icon>
        <v-list-item-title>MQTT</v-list-item-title>
      </v-list-item>
      <v-list-item link>
        <v-list-item-icon>
          <v-icon>mdi-email-send</v-icon>
        </v-list-item-icon>
        <v-list-item-title>SMTP</v-list-item-title>
      </v-list-item>
      <v-list-item link>
        <v-list-item-icon>
          <v-icon>mdi-clock-time-eight</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Datetime</v-list-item-title>
      </v-list-item>
      <v-list-item link to="/json">
        <v-list-item-icon>
          <v-icon>mdi-code-json</v-icon>
        </v-list-item-icon>
        <v-list-item-title>JSON</v-list-item-title>
      </v-list-item>
      <v-list-item link to="/texts">
        <v-list-item-icon>
          <v-icon>mdi-format-text</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Texts</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex'

  export default {
    name: 'Sidebar',
    computed: {
      ...mapGetters([
        'getAuthUser',
      ]),
    },
  }
</script>

<style>

</style>