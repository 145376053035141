<template>
  <div id="todo" class="pa-5">
    <v-card>
      <v-tabs v-model="tab" vertical>
        <v-tab v-for="item in tabs" :key="item.id">
          <template v-if="item.tasks.length">
            <v-badge color="#272727" :content="item.tasks.length">{{ item.name }}</v-badge>
          </template>
          <template v-else>
            {{ item.name }}
          </template>
        </v-tab>

        <v-tab-item v-for="tab in tabs" :key="tab.id">
          <draggable v-model="tab.tasks" handle=".handle" @change="onSortTasks">
            <v-card v-for="task in tab.tasks" :key="task.id" flat>
              <v-card-text class="py-2">
                <v-btn class="handle float-left mt-2" x-small icon>
                  <v-icon>mdi-cursor-move</v-icon>
                </v-btn>
                <v-btn class="float-right mt-2" color="accent" x-small icon @click="onDeleteTask(task)">
                  <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>
                <v-card-text class="task_edit_area pre-formatted mx-7" v-html="task.body" contenteditable="true" @focusout="onChangeTask($event, task.id)"/>
              </v-card-text>
            </v-card>
          </draggable>
        </v-tab-item>
      </v-tabs>
    </v-card>

    <!--add task button-->
    <v-fab-transition>
      <v-btn class="mr-16 mb-10" dark fixed bottom right fab @click="onAddTask">
        <v-icon>mdi-text-box-plus-outline</v-icon>
      </v-btn>
    </v-fab-transition>

    <v-dialog v-model="dialog_sort" scrollable max-width="300px">
      <template v-slot:activator="{ on, attrs }">
        <v-fab-transition>
          <v-btn class="mb-10" dark fixed bottom right fab v-bind="attrs" v-on="on">
            <v-icon>mdi-folder-cog-outline</v-icon>
          </v-btn>
        </v-fab-transition>
      </template>
      <v-card>
        <v-card-title>Projects</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 300px; padding-top: 20px;">
          <draggable :list="tabs" handle=".handle" class="row" @change="onSortProjects">

            <div v-for="(tab, i) in tabs" :key="i" style="width: 100%">
              <v-text-field :value="tab.name" counter="20" maxlength="20">
                <v-icon slot="prepend" class="handle">mdi-cursor-move</v-icon>
              </v-text-field>
            </div>

          </draggable>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="dialog_add = true">Add</v-btn>
          <v-spacer/>
          <v-btn color="blue darken-1" text @click="dialog_sort = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_add" width="300">
      <v-card>
        <v-card-title>Add Project</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 120px; padding-top: 20px;">
          <v-text-field label="Project name" v-model="add_project_name" counter="20" maxlength="20"/>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="onAddProject">Add</v-btn>
          <v-spacer/>
          <v-btn color="blue darken-1" text @click="dialog_add = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_del" max-width="300">
      <v-card>
        <v-card-title class="headline">
          Delete Task?
        </v-card-title>
        <v-card-text>{{ dialog_del_task.body }}</v-card-text>
        <v-card-actions>
          <v-btn color="secondary lighten-3" text @click="dialog_del = false; dialog_del_task = {};">Cancel</v-btn>
          <v-spacer/>
          <v-btn color="accent" text @click="onConfirmTaskDel">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex'
  import draggable from 'vuedraggable'

  export default {
    name: 'Todo',
    components: {
      draggable,
    },
    data: () => {
      return {
        dialog_sort: false,
        dialog_add: false,
        dialog_del: false,
        dialog_del_task: {},
        add_project_name: null,
        tab: 0,
      }
    },
    computed: {
      ...mapGetters([
        'getTasks',
      ]),
      tabs: {
        get: function () {
          return this.getTasks ? this.getTasks : []
        },
        set: function () {
        }
      }
    },
    mounted() {
      this.queryTasks()
    },
    methods: {
      ...mapActions([
        'setToast',
        'queryTasks',
        'queryTaskCreate',
        'queryTaskUpdate',
        'queryTaskDelete',
        'queryTaskProjectCreate',
        'queryTaskProjectUpdate',
        'queryTaskProjectSort',
      ]),
      onAddProject() {
        let data = {
          name: this.add_project_name,
          sort: this.getTasks.length
        }
        this.queryTaskProjectCreate(data)
        this.add_project_name = ''
        this.dialog_add = false
      },
      onSortProjects() {
        let arr = []
        for (let tab of this.tabs)
          arr.push(tab.id)

        this.queryTaskProjectSort(arr)
      },

      onAddTask() {
        if (this.getTasks.length) {
          let data = {
            task_project_id: this.getTasks[this.tab].id,
            sort: this.getTasks[this.tab].tasks.length
          }
          this.queryTaskCreate(data)
        } else {
          this.setToast(['First create a project', 'warning'])
          this.dialog_add = true
        }
      },
      onChangeTask(event, task_id) {
        for (let task of this.tabs[this.tab].tasks)
          if (task.id == task_id && task.body !== event.target.innerText)
            this.queryTaskUpdate([task_id, {body: event.target.innerText}])
      },
      onSortTasks(event) {
        let new_sort = event.moved.newIndex
        let old_sort = event.moved.oldIndex

        this.queryTaskUpdate([this.tabs[this.tab].tasks[new_sort].id, {sort: new_sort}])
        this.queryTaskUpdate([this.tabs[this.tab].tasks[old_sort].id, {sort: old_sort}])
      },
      onMoveTask(task_id, project_id) {
        this.queryTaskUpdate([task_id, {task_project_id: project_id}])
      },
      onDeleteTask(task) {
        this.dialog_del = true
        this.dialog_del_task = task
      },
      onConfirmTaskDel() {
        this.queryTaskDelete(this.dialog_del_task.id)
        this.dialog_del = false
        this.dialog_del_task = {}
      }
    }
  }
</script>

<style lang="scss">
  #todo {
    .v-card__text:focus {
      outline: 0
    }

    .task_edit_area {
      background: #272727;
      border-radius: 3px;
      /*margin-left: 30px;*/
      padding: 7px;
      width: auto;
    }
  }
</style>