export default {
  state: {
    tasks: [],
    task_projects: [],
  },
  mutations: {
    setTasks(state, payload) {
      state.tasks = payload
    },
  },
  actions: {
    queryTasks({dispatch, commit}) {
      dispatch('setPreloader', true)
      axios.get(process.env.VUE_APP_API_ENDPOINT + 'tasks')
        .then(res => {
          dispatch('setPreloader', false)
          commit('setTasks', res.data.tasks)
        })
        .catch((error) => {
          dispatch('setToastHttpError', error.response)
        })
    },
    queryTaskCreate({dispatch, commit}, payload) {
      dispatch('setPreloader', true)
      axios.post(process.env.VUE_APP_API_ENDPOINT + 'tasks', payload)
        .then(res => {
          dispatch('setPreloader', false)
          dispatch('setToast', [res.data.message, 'success'])
          commit('setTasks', res.data.tasks)
        })
        .catch((error) => {
          dispatch('setToastHttpError', error.response)
        })
    },
    queryTaskUpdate({dispatch, commit}, payload) {
      dispatch('setPreloader', true)
      axios.patch(process.env.VUE_APP_API_ENDPOINT + 'tasks/' + payload[0], payload[1])
        .then(res => {
          dispatch('setPreloader', false)
          if (res.data.message)
            dispatch('setToast', [res.data.message, 'success'])
          commit('setTasks', res.data.tasks)
        })
        .catch((error) => {
          dispatch('setToastHttpError', error.response)
        })
    },
    queryTaskDelete({dispatch, commit}, payload) {
      dispatch('setPreloader', true)
      axios.delete(process.env.VUE_APP_API_ENDPOINT + 'tasks/' + payload)
        .then(res => {
          dispatch('setPreloader', false)
          dispatch('setToast', [res.data.message, 'success'])
          commit('setTasks', res.data.tasks)
        })
        .catch((error) => {
          dispatch('setToastHttpError', error.response)
        })
    },
    queryTaskProjectCreate({dispatch, commit}, payload) {
      dispatch('setPreloader', true)
      axios.post(process.env.VUE_APP_API_ENDPOINT + 'tasks/projects', payload)
        .then(res => {
          dispatch('setPreloader', false)
          commit('setTasks', res.data.tasks)
          dispatch('setToast', [res.data.message, 'success'])
        })
        .catch((error) => {
          dispatch('setToastHttpError', error.response)
        })
    },
    queryTaskProjectUpdate({dispatch, commit}, payload) {
      dispatch('setPreloader', true)
      axios.patch(process.env.VUE_APP_API_ENDPOINT + 'tasks/projects/' + payload[0], payload[1])
        .then(res => {
          dispatch('setPreloader', false)
          if (res.data.message)
            dispatch('setToast', [res.data.message, 'success'])
          commit('setTasks', res.data.tasks)
        })
        .catch((error) => {
          dispatch('setToastHttpError', error.response)
        })
    },
    queryTaskProjectSort({dispatch, commit}, payload) {
      dispatch('setPreloader', true)
      axios.patch(process.env.VUE_APP_API_ENDPOINT + 'tasks/projects/sort', {id: payload})
        .then(res => {
          dispatch('setPreloader', false)
          if (res.data.message)
            dispatch('setToast', [res.data.message, 'success'])
          commit('setTasks', res.data.tasks)
        })
        .catch((error) => {
          dispatch('setToastHttpError', error.response)
        })
    },
  },
  getters: {
    getTasks: state => {
      return state.tasks
    },
  }
}