export default {
  state: {
    notes: [],
    note_folders: [],
  },
  mutations: {
    setNotes(state, payload) {
      state.notes = payload
    },
  },
  actions: {
    queryNotes({dispatch, commit}) {
      dispatch('setPreloader', true)
      axios.get(process.env.VUE_APP_API_ENDPOINT + 'notes')
        .then(res => {
          dispatch('setPreloader', false)
          commit('setNotes', res.data.notes)
        })
        .catch((error) => {
          dispatch('setToastHttpError', error.response)
        })
    },
    queryNoteCreate({dispatch, commit}, payload) {
      dispatch('setPreloader', true)
      axios.post(process.env.VUE_APP_API_ENDPOINT + 'notes', payload)
        .then(res => {
          dispatch('setPreloader', false)
          dispatch('setToast', [res.data.message, 'success'])
          commit('setNotes', res.data.notes)
        })
        .catch((error) => {
          dispatch('setToastHttpError', error.response)
        })
    },
    queryNoteUpdate({dispatch, commit}, payload) {
      dispatch('setPreloader', true)
      axios.patch(process.env.VUE_APP_API_ENDPOINT + 'notes/' + payload[0], payload[1])
        .then(res => {
          dispatch('setPreloader', false)
          if (res.data.message)
            dispatch('setToast', [res.data.message, 'success'])
          commit('setNotes', res.data.notes)
        })
        .catch((error) => {
          dispatch('setToastHttpError', error.response)
        })
    },
    queryNoteDelete({dispatch, commit}, payload) {
      dispatch('setPreloader', true)
      axios.delete(process.env.VUE_APP_API_ENDPOINT + 'notes/' + payload)
        .then(res => {
          dispatch('setPreloader', false)
          dispatch('setToast', [res.data.message, 'success'])
          commit('setNotes', res.data.notes)
        })
        .catch((error) => {
          dispatch('setToastHttpError', error.response)
        })
    },
    queryNoteFolderCreate({dispatch, commit}, payload) {
      dispatch('setPreloader', true)
      axios.post(process.env.VUE_APP_API_ENDPOINT + 'notes/folders', payload)
        .then(res => {
          dispatch('setPreloader', false)
          commit('setNotes', res.data.notes)
          dispatch('setToast', [res.data.message, 'success'])
        })
        .catch((error) => {
          dispatch('setToastHttpError', error.response)
        })
    },
    queryNoteFolderUpdate({dispatch, commit}, payload) {
      dispatch('setPreloader', true)
      axios.patch(process.env.VUE_APP_API_ENDPOINT + 'notes/folders/' + payload[0], payload[1])
        .then(res => {
          dispatch('setPreloader', false)
          if (res.data.message)
            dispatch('setToast', [res.data.message, 'success'])
          commit('setNotes', res.data.notes)
        })
        .catch((error) => {
          dispatch('setToastHttpError', error.response)
        })
    },
    queryNoteFolderSort({dispatch, commit}, payload) {
      dispatch('setPreloader', true)
      axios.patch(process.env.VUE_APP_API_ENDPOINT + 'notes/folders/sort', {id: payload})
        .then(res => {
          dispatch('setPreloader', false)
          if (res.data.message)
            dispatch('setToast', [res.data.message, 'success'])
          commit('setNotes', res.data.notes)
        })
        .catch((error) => {
          dispatch('setToastHttpError', error.response)
        })
    },
  },
  getters: {
    getNotes: state => {
      return state.notes
    },
  }
}