<template>
  <div id="notes" class="pa-5">
    <!-- tabs -->
    <v-card>
      <v-tabs v-model="tab" centered>
        <v-tab v-for="item in tabs" :key="item.id">
          {{ item.name }}
        </v-tab>
      </v-tabs>
    </v-card>

    <!--tab content-->
    <v-tabs-items class="transparent-bg mt-6" v-model="tab">
      <v-tab-item v-for="tab in tabs" :key="tab.id">
        <!--tab-->
        <draggable v-model="tab.notes" handle=".handle" class="row" @change="onSortNotes">
          <v-col cols="12" md="6" lg="6" v-for="note of tab.notes" :key="note.id">
            <v-card class="note" flat>
              <!--buttons-->
              <div class="manage px-3">
                <v-btn class="handle" x-small icon>
                  <v-icon>mdi-cursor-move</v-icon>
                </v-btn>
                <v-btn class="float-right mt-1" color="accent" x-small icon @click="onDeleteNote(note)">
                  <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="float-right mt-1 mx-3" x-small icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-folder-edit-outline</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item v-for="(folder, index) in tabs" :key="index" v-if="tab.name !== folder.name" link @click="onMoveNote(note.id, folder.id)">
                      <v-list-item-title>{{ folder.name }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
              <!--note-->
              <perfect-scrollbar>
                <v-card-text class="note_edit_area py-0 pre-formatted" v-html="note.body" contenteditable="true" @focusout="onChangeNote($event, note.id)"/>
              </perfect-scrollbar>
            </v-card>
          </v-col>
        </draggable>
      </v-tab-item>
    </v-tabs-items>

    <!--add note button-->
    <v-fab-transition>
      <v-btn class="mr-16 mb-10" dark fixed bottom right fab @click="onAddNote">
        <v-icon>mdi-note-plus-outline</v-icon>
      </v-btn>
    </v-fab-transition>

    <v-dialog v-model="dialog_sort" scrollable max-width="300px">
      <template v-slot:activator="{ on, attrs }">
        <v-fab-transition>
          <v-btn class="mb-10" dark fixed bottom right fab v-bind="attrs" v-on="on">
            <v-icon>mdi-folder-cog-outline</v-icon>
          </v-btn>
        </v-fab-transition>
      </template>
      <v-card>
        <v-card-title>Folders</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 300px; padding-top: 20px;">
          <draggable :list="tabs" handle=".handle" class="row" @change="onSortFolders">

            <div v-for="(tab, i) in tabs" :key="i" style="width: 100%">
              <v-text-field :value="tab.name" counter="20" maxlength="20">
                <v-icon slot="prepend" class="handle">mdi-cursor-move</v-icon>
              </v-text-field>
            </div>

          </draggable>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="dialog_add = true">Add</v-btn>
          <v-spacer/>
          <v-btn color="blue darken-1" text @click="dialog_sort = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_add" width="300">
      <v-card>
        <v-card-title>Add Folder</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 120px; padding-top: 20px;">
          <v-text-field label="Folder name" v-model="add_folder_name" counter="20" maxlength="20"/>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="onAddFolder">Add</v-btn>
          <v-spacer/>
          <v-btn color="blue darken-1" text @click="dialog_add = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_del" max-width="300">
      <v-card>
        <v-card-title class="headline">
          Delete Note?
        </v-card-title>
        <v-card-text>{{ dialog_del_note.body }}</v-card-text>
        <v-card-actions>
          <v-btn color="secondary lighten-3" text @click="dialog_del = false; dialog_del_note = {};">Cancel</v-btn>
          <v-spacer/>
          <v-btn color="accent" text @click="onConfirmNoteDel">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex'
  import draggable from 'vuedraggable'

  export default {
    name: 'Notes',
    components: {
      draggable,
    },
    data: () => {
      return {
        dialog_sort: false,
        dialog_add: false,
        dialog_del: false,
        dialog_del_note: {},
        add_folder_name: null,
        tab: 0,
      }
    },
    computed: {
      ...mapGetters([
        'getNotes',
      ]),
      tabs: {
        get: function () {
          return this.getNotes ? this.getNotes : []
        },
        set: function () {
        }
      }
    },
    mounted() {
      this.queryNotes()
    },
    methods: {
      ...mapActions([
        'setToast',
        'queryNotes',
        'queryNoteCreate',
        'queryNoteUpdate',
        'queryNoteDelete',
        'queryNoteFolderCreate',
        'queryNoteFolderUpdate',
        'queryNoteFolderSort',
      ]),
      onAddFolder() {
        let data = {
          name: this.add_folder_name,
          sort: this.getNotes.length
        }
        this.queryNoteFolderCreate(data)
        this.add_folder_name = ''
        this.dialog_add = false
      },
      onSortFolders() {
        let arr = []
        for (let tab of this.tabs)
          arr.push(tab.id)

        this.queryNoteFolderSort(arr)
      },

      onAddNote() {
        if (this.getNotes.length) {
          let data = {
            note_folder_id: this.getNotes[this.tab].id,
            sort: this.getNotes[this.tab].notes.length
          }
          this.queryNoteCreate(data)
        } else {
          this.setToast(['First create a folder', 'warning'])
          this.dialog_add = true
        }
      },
      onChangeNote(event, note_id) {
        for (let note of this.tabs[this.tab].notes)
          if (note.id == note_id && note.body !== event.target.innerText)
            this.queryNoteUpdate([note_id, {body: event.target.innerText}])
      },
      onSortNotes(event) {
        let new_sort = event.moved.newIndex
        let old_sort = event.moved.oldIndex

        this.queryNoteUpdate([this.tabs[this.tab].notes[new_sort].id, {sort: new_sort}])
        this.queryNoteUpdate([this.tabs[this.tab].notes[old_sort].id, {sort: old_sort}])
      },
      onMoveNote(note_id, folder_id) {
        this.queryNoteUpdate([note_id, {note_folder_id: folder_id}])
      },
      onDeleteNote(note) {
        this.dialog_del = true
        this.dialog_del_note = note
      },
      onConfirmNoteDel() {
        this.queryNoteDelete(this.dialog_del_note.id)
        this.dialog_del = false
        this.dialog_del_note = {}
      }
    }
  }
</script>

<style lang="scss">
  #notes {
    .note {
      overflow: hidden;
      .ps {
        height: 140px;
        width: 100%;
        margin-top: 10px;
        .v-card__text:focus {
          outline: 0
        }
        .note_edit_area {
          min-height: 130px;
        }
      }
      .manage {
        position: relative;
        border-bottom: 1px solid #333;
        height: 30px;
      }
    }
  }
</style>