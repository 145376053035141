<template>
  <div id="json" class="pa-5">
    <v-card>
      <v-card-title>JSON Formatting</v-card-title>
      <v-card-text>
        <v-textarea
            counter
            label="Enter json"
            v-model="json"
            class="json_input"
        />
      </v-card-text>
    </v-card>

    <v-toolbar class="mt-2" color="#1e1e1e">
      <v-btn-toggle class="mr-2" dense background-color="transparent">
        <v-btn @click="onMinify">Minify</v-btn>
        <v-btn @click="onFormat">Format</v-btn>
        <v-btn @click="onClear">Clear</v-btn>
      </v-btn-toggle>
      <v-spacer></v-spacer>
      <v-text-field
          label="Search in Result JSON"
          hide-details
          single-line
          @input="onSearch"
      />
    </v-toolbar>

    <v-card class="mt-2" v-if="result">
      <v-card-title>
        <div>Result</div>
        <div class="ml-2 font-italic subtitle-2" v-if="count_matches">found {{ count_matches }} matches</div>
        <v-btn class="ml-auto" elevation="2" small @click="setToast(['Copied', 'default'])" v-clipboard="() => result">
          Copy to clipboard
        </v-btn>
      </v-card-title>
      <v-card-text>
        <div class="result"
             :class="[pre_formatted ? 'pre-formatted word-break' : '']"
             v-html="result"></div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  import {mapActions} from 'vuex'

  export default {
    name: 'Json',
    data: () => {
      return {
        json: null,
        result: null,
        pre_formatted: true,
        count_matches: 0,
      }
    },
    methods: {
      ...mapActions([
        'setToast',
      ]),
      onMinify() {
        this.pre_formatted = false
        let result = JSON.stringify(JSON.parse(this.json));
        result = result.replace(/\s/g, '');
        this.result = result.replace(/\r?\n/g, '');
      },
      onFormat() {
        this.pre_formatted = true
        this.result = JSON.stringify(JSON.parse(this.json), null, 4);
      },
      onSearch(text) {
        this.count_matches = 0
        text = text.trim()
        if (text) {
          this.result = this.result.replaceAll(text, "<b>" + text + "</b>");
          var re = new RegExp(text, 'g');
          this.count_matches = (this.result.match(re) || []).length
        }
      },
      onClear() {
        this.json = null
        this.result = null
      }
    }
  }
</script>

<style lang="scss">
  #json {
    .json_input {
      line-height: 20px;
    }
    .result {
      font-size: 13px;
      line-height: 15px;
      b {
        color: #02ffff;
      }
    }
  }
</style>