import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import MqttIcon from '@/components/Icons/Mqtt'

Vue.use(Vuetify);


export default new Vuetify({
  icons: {
    iconfont: 'mdiSvg', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    values: {
      mqtt: {
        component: MqttIcon,
      },
    },
  },
  theme: {
    dark: true,
    themes: {
      light: {
        primary: '#3f51b5',
        secondary: '#b0bec5',
        accent: '#8c9eff',
        error: '#b71c1c',
      },
      // dark: {
      //   primary: '#3f51b5',
      //   secondary: '#b0bec5',
      //   accent: '#8c9eff',
      //   error: '#b71c1c',
      // },
    },
  },
});
