<template>
  <div id="dashboard" class="pa-5">
    //
  </div>
</template>

<script>
  export default {
    name: 'Dashboard'
  }
</script>

<style>

</style>